<template>
  <section>
    <b-overlay
      show
      spinner-variant="primary"
      spinner-type="grow"
      spinner-large
      rounded="sm"
      style="top: 25em;"
      v-if="overlayStatus"
    >
    </b-overlay>
    <div v-if="data.length === 0 && overlayStatus === false">
      <Empty
        icon="LoaderIcon"
        title="No hay datos para mostrar"
        content="Actualmente no hay órdenes activas, pero puedes crear una orden de compra al presionar el siguiente botón:"
        button-text="Crear orden de compra"
        :button-func="goToPurchaseOrders"
      />
    </div>
    <b-row class="match-height">
      <b-col
        lg="4"
        md="4"
        v-for="(order, indexOrder) in data"
        :key="indexOrder"
      >
        <b-card>

          <!-- Header -->
          <b-row class="justify-content-md-center">
            <b-col
              md="12"
              class="d-flex justify-content-center"
            >
              <h3><b>{{ order.companyName }},  Orden #{{ order.idOrder }}  </b></h3>
            </b-col>
          </b-row>
          <br>

          <!-- Interactions -->
          <b-row class="invoice-padding pt-0" align-h="around">
            <b-col
              md="12"
              class="d-flex justify-content-center"
            >
              <v-select
                style="width: 35em"
                label="name"
                :clearable="false"
                :options="shippingStatus"
                :reduce="name => name.id"
                :disabled="order.shipmentStatus === 'ENTREGADO'"
                @input='showModal(order)'
                v-model="order.idShipmentStatus"
              />
            </b-col>
          </b-row>
          <br>

          <!-- Chart -->
          <b-row>
            <b-col
              md="6"
            >
              <div v-if="order.amount != 0">
                <vue-apex-charts
                  class="d-flex justify-content-center"
                  type="radialBar"
                  height="80"
                  width="80"
                  :series="chartPaymentData[indexOrder].series"
                  :options="chartPaymentData[indexOrder].options"
                />
                <h5 class="font-weight-bold text-body-heading" style="text-align: center">{{ remainingDays(order.deadlinePayment, 'payment') }}</h5>
              </div>
              <div v-else style="text-align: center">
                <b-avatar
                  style="margin-bottom: 1em; margin-top: 1em"
                  rounded
                  size="60"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="CheckIcon"
                  />
                </b-avatar>
                <h5 class="font-weight-bold text-body-heading" style="text-align: center">Pago completado</h5>
              </div>
            </b-col>
            <b-col
              md="6"
            >
              <div v-if="order.shipmentStatus !== 'ENTREGADO'">
                <vue-apex-charts
                  class="d-flex justify-content-center"
                  type="radialBar"
                  height="80"
                  width="80"
                  :series="chartShippingData[indexOrder].series"
                  :options="chartShippingData[indexOrder].options"
                />
                <h5 class="font-weight-bold text-body-heading" style="text-align: center">{{ remainingDays(order.deadlineDelivery, 'shipping') }}</h5>
              </div>
              <div v-else style="text-align: center">
                <b-avatar
                  style="margin-bottom: 1em; margin-top: 1em"
                  rounded
                  size="60"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="CheckIcon"
                  />
                </b-avatar>
                <h5 class="font-weight-bold text-body-heading" style="text-align: center">Envío completado</h5>
              </div>
            </b-col>
          </b-row>
          <br>

          <!-- Footer -->
          <div 
            :class="'bg-light-' + priority(order.deadlinePayment, order.amount) + ' rounded'"
            style="margin-bottom: 1em"
          >
            <b-row class="justify-content-md-center">
              <b-col
                md="11"
                class="d-flex justify-content-center "
              >
                <div v-if="order.amount != 0" style="padding: 1em">
                  <div>
                    <sup class="text-body">
                      <small>$</small>
                    </sup>
                    <h2 class="d-inline mr-25">
                      {{ formatterNumber(order.amount) }}
                    </h2>
                    <sub class="text-body">
                      <small>de abonos restantes</small>
                    </sub>
                  </div>
                </div>
                <div v-else style="padding: 1em">
                  <h2 class="d-inline mr-25">
                    ¡Completado!
                  </h2>
                </div>
              </b-col>
            </b-row>
            <b-row class="justify-content-md-center">
              <b-col
                md="11"
              >
                <b-progress
                  :value="order.total - order.amount"
                  :max="order.total"
                  height="8px"
                  :variant="priority(order.deadlinePayment, order.amount)"
                />
              </b-col>
            </b-row>
            <br>
          </div>
          <b-row class="justify-content-md-center">
            <b-col
              md="12"
            >
              <b-button
                block
                variant="primary"
                :disabled="order.amount == 0"
                @click="$router.push({ name: 'paymentBills', params: { id: order.idCompany, paymentType: 'accountsPayable' }})"
              >
                Pagar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- MODAL CONFIRMACIÓN -->
    <b-modal
      id="confirmModal"
      title="Confirmar"
      ok-title="Aceptar"
      cancel-title="Voy a revisarlo"
      cancel-variant="outline-secondary"
      @ok="updateShippingStatus($event)"
      @cancel="cancel()"
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <h3 style="margin-bottom: 1em">¿Seguro que deseas editar esta orden?</h3>
      <p style="font-size: medium">De ser necesario, revisa bien tus cambios.</p>
      <hr>
      <template v-if="this.currentOrder.idShipmentStatus == 7">
        <label>¿Hay mercancía sobrante/faltante?</label>
        <v-select
          :options="modalOptions"
          :clearable="false"
          v-model="leftOver"
        />
        <div v-if="leftOver === 'FALTANTE' || leftOver === 'SOBRANTE'">
          <b-form-input
            style="margin-top: 1em"
            v-model="leftOverQuantity"
            placeholder="Ingrese la cantidad de dinero"
            :state="inputState"
            :formatter="limitAmount"
            @keypress="restrictNumber($event)"
            id="amount"
            type="number"
          />
        </div>
      </template>
    </b-modal>
  </section>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>

br {
  user-select: none;
}

</style>


<script>
import Empty from '../../components/empty-page/Empty'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import utils from '../../../utils/utils.js';

import { BCard, BRow, BCol, BButton, BProgress, BFormInput, BOverlay, BAvatar } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    vSelect,
    VueApexCharts,
    BProgress,
    BFormInput,
    BOverlay,
    BAvatar,
    Empty
  },
  data() {
    return {

      // Data
      data: [],
      lastOptions: [],
      chartPaymentData: [],
      chartShippingData: [],
      maxAmount: 0,

      // Chart template
      chart: {
        series: [20],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '50%',
              },
              track: {
                background: '#e9ecef',
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },

      // Props
      shippingStatus: [
        {
          id: 5,
          name: 'Sin enviar'
        },
        {
          id: 6,
          name: 'En curso'
        },
        {
          id: 7,
          name: 'Entregado'
        }
      ],
      modalOptions: ['No aplica', 'FALTANTE', 'SOBRANTE'],
      leftOver: 'No aplica',
      leftOverQuantity: null,
      inputState: null,
      currentOrder: {},
      overlayStatus: true,
    }
  },
  created() {
    this.getPriorityOrders();
    this.timer = setInterval(this.getPriorityOrders, 300000);
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    next();
  },
  methods: {
    getPriorityOrders() {
      this.overlayStatus = true;
      this.$axios.get(this.$services + 'orders/get_priority_orders', {
        params: {
          type: 'COMPRA'
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.data = res.data.data;
          res.data.data.forEach(element => {
            this.lastOptions.push(element.idShipmentStatus)
          });
          for (let i = 0; i < this.data.length; i++) {
            this.setChartData('payment', i);
            this.setChartData('shipping', i);
          }
        } else
        throw 'Error al obtener datos.';
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('danger', String(error));
      }).finally(() => {
        this.overlayStatus = false;
      });
    },
    updateShippingStatus(modalEvent) {
      if (this.leftOver === 'FALTANTE' || this.leftOver === 'SOBRANTE')
        if (this.leftOverQuantity === '' || this.leftOverQuantity == null) {
          this.inputState = false;
          modalEvent.preventDefault();
          return;
        }


      this.$axios.post(this.$services + 'orders/update_shipping_status', {
        idOrder: String(this.currentOrder.idOrder),
        idShippingStatus: String(this.currentOrder.idShipmentStatus),
        idCompany: String(this.currentOrder.idCompany),
        type: 'COMPRA',
        deliveryDate: this.currentOrder.idShipmentStatus == 7 ? this.$moment().valueOf() : null,
        action: this.currentOrder.idShipmentStatus == 7 ? this.leftOver : null,
        amount: this.currentOrder.idShipmentStatus == 7 ? parseFloat(this.leftOverQuantity) : null,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.getPriorityOrders();
          this.showToast('success', '👋  ¡Orden actualizada con éxito!');
        } else
        throw 'Error al actualizar datos.';
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('danger', String(error));
      }).finally(() => {});
    },
    setChartData(type, index) {

      // Template
      const chartClone = JSON.parse(JSON.stringify(this.chart));
      let priorityColor;

      switch (type) {
        case 'payment':
          priorityColor = this.priority(this.data[index].deadlinePayment);
          priorityColor = this.getColor(priorityColor);
          chartClone.options.colors[0] = priorityColor;  // Color de prioridad

          chartClone.series[0] = this.getFilling(this.data[index].deadlinePayment, this.data[index].creationDate);  // Progreso de la barra
          this.chartPaymentData.push(chartClone);
          break;

        case 'shipping':
          priorityColor = this.priority(this.data[index].deadlineDelivery);
          priorityColor = this.getColor(priorityColor);
          chartClone.options.colors[0] = priorityColor;  // Color de prioridad

          chartClone.series[0] = this.getFilling(this.data[index].deadlineDelivery, this.data[index].creationDate);  // Progreso de la barra
          this.chartShippingData.push(chartClone);
          break;
      }
    },
    remainingDays(timeStamp, type) {

      // Diferencia de días
      let expression;
      let days = this.diffDays(timeStamp);
      
      (Math.abs(days) == 1) ? expression = ' día' : expression = ' días';

      switch (type) {
        case 'payment':

          if (days < 0) { // Delay
            return 'Pago retrasado (' + Math.abs(days) + expression + ')';
          } else if (days == 0) { // Important
            return 'Pagar hoy';
          } else if (days >= 1 && days <= 3) { // Warning
            return 'Pagar en menos de ' + days + expression;
          } else { // Not warnign
            return 'Pagar en menos de ' + days + expression;
          }
      
        case 'shipping':
          if (days < 0) { // Delay
            return 'Envío retrasado (' + Math.abs(days) + expression + ')';
          } else if (days == 0) { // Important
            return 'Llega hoy';
          } else if (days >= 1 && days <= 3) { // Warning
            return 'Llega en menos de ' + days + expression;
          } else { // Not warnign
            return 'Llega en menos de ' + days + expression;
          }
      }
    },
    priority(timeStamp, paymentAmount = null) {

      // Se ha completado el pago
      if (paymentAmount == 0)
        return 'primary';

      let days = this.diffDays(timeStamp);

      if (days <= 1) { // Delay
        return 'danger';
      } else if (days >= 2 && days <= 3) { // Warning
        return 'warning';
      } else { // Not warnign
        return 'primary';
      }
    },
    getColor(priority) {
      switch (priority) {
        case 'danger':
          return $themeColors.danger;

        case 'warning':
          return $themeColors.warning;

        case 'primary':
          return $themeColors.primary;
      }
    },
    getFilling(deadlineDate, creationDate) {

      // Diferencia de días
      let lastDay = this.$moment(deadlineDate);
      let totalTime = Math.abs(lastDay.diff(creationDate, 'hours'));
      let timePassed = totalTime - lastDay.diff(this.$moment(), 'hours');

      // Regla de 3
      return (timePassed * 100 / totalTime);
    },
    diffDays(timeStamp) {

      // Set 00:00 both dates
      let today = this.$moment().set({hour:0, minute:0, second:0, millisecond:0});
      let deadline = this.$moment(timeStamp).set({hour:0, minute:0, second:0, millisecond:0});

      let diffDays = deadline.diff(today, 'days');

      return diffDays;
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    showModal(order) {
      // Reiniciar modal
      this.inputState = null;
      this.leftOver = 'No aplica';
      this.leftOverQuantity = null;
      this.currentOrder = order;
      this.maxAmount = this.currentOrder.total;
      // Mostrar modal
      this.$bvModal.show('confirmModal');
    },
    formatterNumber(value) {
      return utils.numberWithCommas(value);
    },
    goToPurchaseOrders() {
      this.$router.push({ name: 'add-purchaseOrders' })
    },
    cancel(){
      let index = this.data.findIndex(order => 
        order.idOrder == this.currentOrder.idOrder
      );
      this.data[index].idShipmentStatus = this.lastOptions[index];
    },
    restrictNumber(event){ // Solo numeros pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
      }
    },
    limitAmount(e){
      if (parseInt(e) >= this.maxAmount) {
        return this.maxAmount;
      } else if (parseInt(e) < 0) {
        return 0;
      } else {
        return e;
      }
    },
  }
}
</script>
